.soldList {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .setContainer {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
    }
}