.searchBar {
    height: 50px;
    display: flex;
    padding: 0 10px;
    margin: 10px 0;
    align-items: center;
    justify-content: space-between;

    .searchInput {
        width: 100%;
    }
}


@media screen and (min-width: 750px) {
	.searchBar {
        margin: 10px 50px;
	}
}

@media screen and (min-width: 1000px) {
	.searchBar {
        margin: 10px 150px;
	}
}