// .login {
//     margin: 120px auto;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;


//     .input {
//         width: 50%;
//         margin: 25px 0;
//     }
// }


.login {
    margin: 80px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    .input {
        width: 100%;
        margin: 25px 0;
    }

    .loginButton {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}