.haveItem {
    margin: 15px 0;
    width: 100%;
    box-sizing: border-box;
    align-self: stretch;
}

@media screen and (min-width: 750px) {
	.haveItem {
        margin: 15px 5px;
		width: 48%;
	}
}

@media screen and (min-width: 1000px) {
	.haveItem {
		width: 32%;
	}
}