.orderList {
    .order {
        margin-bottom: 10px;
        .content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;

            .w15, .w20, w25 {
                width: 100%;
            }
        }
    }

    @media screen and (min-width: 750px) {
        .order {
            .content {
                flex-direction: row;
                .w25 {
                    width: 25%;
                }
    
                .w20 {
                    width: 20%;
                }

                .w15 {
                    width: 15%;
                }

                .w10 {
                    width: 10%;
                }
            }
        }
    }
}