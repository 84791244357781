.instructions {

    :global {
        .MuiTypography-body2  {
            word-break: break-word;
        }
    }
    

    .endButton {
        :global {
            .MuiIconButton-root {
                padding: 5px;
            }
        }
    }
    .pointer {
        &:hover {
            cursor: pointer;
        }
    }
}