.registerDialog {
    width: 500px;
    margin: 0 auto;
    max-width: 100%;
    span {
        font-weight: 600;
    }

    .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;

        padding: 10px 65px;
    }
}

.MuiInputBase-input {
    text-align: center;
}