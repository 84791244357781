.needList {
	.searchContainer {
		display: flex;
		margin: 10px 150px;
		padding: 0 10px;
		align-items: center;
		justify-content: space-between;

		.searchInput {
			width: 100%;
		}
    }
    
    .spinner {
        text-align: center;
        margin-top: 30px;
    }

	.listItem {

        span {
            font-weight: 600;
        }
		width: 100%;
		display: flex;
        align-items: center;
		border-top: 1px solid #ccc;

		.information {
			width: 60%;

			h2 {
				font-size: 1.1rem;
			}
		}

		.buttons {
			width: 20%;
			display: flex;
			flex-direction: column;
            align-items: flex-end;
            justify-content: center;
		}
	}
}
